<template>
  <div class="d-flex justify-content-center h-100">
    <h1 class="my-auto vertical-align saving">Logging out your session<span>.</span><span>.</span><span>.</span></h1>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { API_URL_v3, cookieAppDomain, cookieDomain, JWT_CHROME_LOCAL_STORAGE } from '@/constants'
import store from '@/store'
import { authenticationTypes } from '@/state/modules/mutation-types'
import http from '@/mixins/http-lib'
import vueCookie from 'vue-cookie'

export default ({
  'name': 'logout',
  data: function () {
    return {
      loadComponents: false
    }
  },
  created () {
  },
  mounted () {
    http.post(`${API_URL_v3}auth/user/logout`, {}).then(
      resp => {
        store.commit(authenticationTypes.SET_JWT_TOKEN, null)
        store.commit(authenticationTypes.SET_IS_AUTHENTICATED, false)
        vueCookie.delete(JWT_CHROME_LOCAL_STORAGE, { domain: cookieDomain })
        vueCookie.delete(JWT_CHROME_LOCAL_STORAGE, { domain: cookieAppDomain })
        window.localStorage.removeItem(JWT_CHROME_LOCAL_STORAGE);

        // vueCookie.set(JWT_LOCAL_STORAGE, null, { expires: '1Y', domain: cookieDomain })
        // vueCookie.set(JWT_LOCAL_STORAGE, null, { expires: '1Y', domain: cookieAppDomain })
        this.$router.push({name: 'login'})
      },
      error => {
      }
    )
  },
  methods: {
    ...mapActions([
      'postRequest'
    ]),
  }
})
</script>

<style scoped>
.vertical-align {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
@keyframes blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}

.saving span {
  animation-name: blink;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  font-size: 48px;
}

.saving span:nth-child(2) {
  animation-delay: .2s;
}

.saving span:nth-child(3) {
  animation-delay: .4s;
}
</style>
